// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-display {
    background-color: #ffe6e6;
    color: #cc0000;
    border: 1px solid #cc0000;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
  }
  
  .error-display h4 {
    margin: 0;
    font-size: 16px;
  }
  
  .error-display p {
    margin: 5px 0 0 0;
    font-size: 14px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ErrorDisplay.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,aAAa;IACb,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".error-display {\r\n    background-color: #ffe6e6;\r\n    color: #cc0000;\r\n    border: 1px solid #cc0000;\r\n    padding: 10px;\r\n    margin: 10px 0;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .error-display h4 {\r\n    margin: 0;\r\n    font-size: 16px;\r\n  }\r\n  \r\n  .error-display p {\r\n    margin: 5px 0 0 0;\r\n    font-size: 14px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
